import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { getQueryStringParams as qs } from 'utils/query-string';
import { getCookie } from 'utils/cookie';

const SiteLayout = dynamic(() => import('components/organisms/Layout/SiteLayout'));
const Login = dynamic(() => import('components/templates/Login'));

export default function Main() {
  const router = useRouter();

  useEffect(() => {
    const { referral } = qs(location.search);

    if (referral) {
      console.info(
        `%c set your referral code of ${referral} `,
        'background-color:green; color:#ffffff;'
      );
      sessionStorage.setItem('_ref', referral);
    }
    if (getCookie({ name: 'token' })) {
      router.replace('/');
      return null;
    }
  }, []);

  return (
    <SiteLayout>
      <Login />
    </SiteLayout>
  );
}
